import React from 'react';
import styles from './JoinGame.module.scss';
import { Button, Input } from '@material-ui/core';

function PinEntry() {
  return (
    <form>
      <Input className={styles.textBox}
        style={{
          fontSize: "1.6rem",
          textAlign: "center",
          fontWeight: "bold",
          margin: "1rem 0"
        }}
        type='text' name="nickname" placeholder="NICKNAME" fullWidth></Input>
      <Input className={styles.textBox}
        style={{
          fontSize: "1.6rem",
          textAlign: "center",
          fontWeight: "bold",
          margin: "1rem 0"
        }}
        type='text' name="gamepin" placeholder="GAME PIN" fullWidth></Input>
      <Button
        style={{
          fontSize: "1.6rem",
          textAlign: "center",
          fontWeight: "bold",
          margin: "1rem 0"
        }}
        variant="contained"
        color="primary"
        type="submit"
        fullWidth

      >
        JOIN
      </Button>
    </form>
  );
}

export default PinEntry;