import React from 'react';
import '../Generic/generic.css';
import Logo from '../Generic/logo';

function Lobby() {
  return (
    <div>
      <div className="HeaderLine">
        <center>
          <div className='HeaderDiv'>
            Join at <b>www.Oflingo.com</b><br />
            with Game PIN : <h1>123 456</h1>
          </div>
        </center>

      </div>


      <div className="CenterBody">
      <Logo heightlength={'10vmin'} />
      <br/>
      <br/>
      <b>2</b> Players joined
      <br/>
      Rohit
      <br/>
      Mohit
      <br/>
      <br/>
      <button type="submit">Start Quiz</button>
      </div>
      

    </div>
  );
}

export default Lobby;