import React from 'react';
import '../Generic/generic.css';

function GameDetails({pin}) {
  return (
    <div className="FooterLine">
        Join at <b>www.Oflingo.com</b>  with Game PIN : <b>{pin}</b>
    </div>
  );
}

export default GameDetails;