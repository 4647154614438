import React from 'react';
import '../Generic/generic.css';
import QuestionWithOption from './QuestionWithOption';

function QuestionBlock() {
  const options = {a:'Option AA', b:'Option BB', c:'Option CC', d:'Option DD'}
  return (
    <div>
      <QuestionWithOption
        ansCapturedCount={7}
        pin={'12 3456'}
        question={'What is the question, koi toh batao'}
        options={options}
        cQuesCount={4}
        tQuesCount={10} />
    </div>
  );
}

export default QuestionBlock;