import React from 'react';
import '../Generic/generic.css';
import { Grid } from '@material-ui/core';
import OptionDiv from './OptionDiv';
import GameDetails from './GameDetails';

function QuestionWithOption({pin, ansCapturedCount, tQuesCount, cQuesCount, question, options }) {

  return (

    <Grid container spacing={2} className='options'>      
      <Grid item xs={12} className='question'>
        <item>{question} ?</item>
      </Grid>
      <Grid item xs={6} className='white-background left-aligned'>
        <item>Timer</item>
      </Grid>
      <Grid item xs={6} className='white-background right-aligned'>
        <item>{ansCapturedCount} answer recieved</item>
      </Grid>
      <Grid item xs={6} className='red'>
        <item><OptionDiv buttonValue={options.a} /></item>
      </Grid>
      <Grid item xs={6} className='blue'>
        <item><OptionDiv buttonValue={options.b}/></item>
      </Grid>
      <Grid item xs={6} className='orange'>
        <item><OptionDiv buttonValue={options.c}/></item>
      </Grid>
      <Grid item xs={6}  className='green'>
        <item><OptionDiv buttonValue={options.d}/></item>
      </Grid>
      <Grid item xs={10}>
        <item><GameDetails pin={pin}/></item>
      </Grid>
      <Grid item xs={2} className='right-aligned'>
        <item>{cQuesCount} / {tQuesCount}</item>
      </Grid>    
    </Grid>
  );
}

export default QuestionWithOption;