import React from 'react';
import '../Generic/generic.css';
import Logo from '../Generic/logo';

function ConfirmQuizQuestions() {
  return (
    <div>
      <div className="HeaderLineWithLogo">
        <Logo heightlength={'5vmin'} />
      </div>

      <br />
      <br />
      <div className="CenterBody">
        <form  action='/#/quizQuestionConfirmation'>
        <h1>NEW QUIZ</h1>
        <b>Name : </b> Quiz Name<br/>
        <b>Category : </b> Category Name<br/>
        <b>Question 1 : </b><br/>
        What is Question number 1?Answer:Option_A<br/>
        <b>Question 2 : </b><br/>
        What is Question number 2?Answer:Option_B<br/>
        <b>Question 3 : </b><br/>
        What is Question number 3?Answer:Option_C<br/>
        <b>Question 4 : </b><br/>
        What is Question number 4?Answer:Option_D<br/>


        <br /><br />
        <button type='submit'>Back</button><button type='submit'>Confirm</button>
        </form>
      </div>

    </div>
  );
}

export default ConfirmQuizQuestions;