import React from 'react';
import logo from './Bataona_logo_t.png';
import './generic.css';

function Logo({heightlength}) {
  const params = {
    style: {
      height: heightlength,
      pointerEvents: 'none'
    },
    test : 'hello'
  };

return (
  <img src={logo} style={params.style} alt="logo" />
);
}

export default Logo;