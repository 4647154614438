import React from 'react';
import '../Generic/generic.css';

function GetReady() {
  return (
    <div>
      <div className="HeaderLine">
        <b>Rohit</b>
        <br />
        <b>PIN : 123 456</b>
      </div>

      <br />
      <br />
      <div className="CenterBody">
        Get Ready !!
      </div>

    </div>
  );
}

export default GetReady;