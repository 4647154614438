import React from 'react';
import styles from './home.module.scss';
import Logo from '../Generic/logo';
import { Grid } from '@material-ui/core';
import HomeBtn from './HomeBtn';

function Home() {
  return (
    <div className={styles.home}>
      <Grid container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={1} sm={3} md={4} xl={4}></Grid>
        <Grid container
          direction="row"
          alignItems="center"
          justifyContent="center"
          xs={10} sm={6} md={4} xl={4}
          className={styles.homecenter}
        >
          <Logo heightlength={'10vmin'} />
          <HomeBtn btnText={"JOIN"} btnAction={"/#/joinGame"} />
          <HomeBtn isDisabled={true} btnText={"HOST"} btnAction={"/#/hostGame"} />
          <p style={{ fontWeight: "bold", fontSize: "2rem", color: "GREY" }}>OR</p>
          <HomeBtn btnText={"CREATE"} btnAction={"/#/createQuiz"} />
        </Grid>
        <Grid item xs={1} sm={3} md={4} xl={4}></Grid>
      </Grid>

    </div>
  );
}

export default Home;