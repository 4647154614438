import React from 'react';
import '../Generic/generic.css';
import Link from '../Generic/Link';

function Pages() {
  return (
    <div>
      <div className="HeaderLine">
        <b>Rohit</b>
        <br />
        <b>PIN : 123 456</b>
      </div>

      <br />
      <br />
      <div className="CenterBody">
        <Link linkTitle={'PlayerInstructions'} linkDesc={''} linkUrl={'/#/playerInstructions'} />
        <Link linkTitle={'GetReady'} linkDesc={''} linkUrl={'/#/getReady'} />
        <Link linkTitle={'Lobby'} linkDesc={''} linkUrl={'/#/lobby'} />
        <Link linkTitle={'createQuiz'} linkDesc={''} linkUrl={'/#/createQuiz'} />
        <Link linkTitle={'quizQuestionEntry'} linkDesc={''} linkUrl={'/#/quizQuestionEntry'} />
        <Link linkTitle={'quizQuestionConfirmation'} linkDesc={''} linkUrl={'/#/quizQuestionConfirmation'} />
        <Link linkTitle={'questionBlock'} linkDesc={''} linkUrl={'/#/questionBlock'} />
      </div>

    </div>
  );
}

export default Pages;