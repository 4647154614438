import React from 'react';
import '../Generic/generic.css';
import Logo from '../Generic/logo';

function CreateQuizQuestionEntry() {
  return (
    <div>
      <div className="HeaderLineWithLogo">
        <Logo heightlength={'5vmin'} />
      </div>

      <br />
      <br />
      <div className="CenterBody">
        <h1>NEW QUIZ</h1>
        <form action='/#/quizQuestionConfirmation'>
          <input type='text' name="question" placeholder="Question ?" width="500px" /><br />
          <input type='text' name="optionA" placeholder="Option A" /> <input type='text' name="optionB" placeholder="Option B" /><br />
          <input type='text' name="optionC" placeholder="Option C" /> <input type='text' name="optionD" placeholder="Option D" /><br />
          <select id="rightAnswer" name="rightAnswer">
            <option value="">CHOOSE RIGHT ANSWER</option>
            <option value="A">OPTION A</option>
            <option value="B">OPTION B</option>
            <option value="C">OPTION C</option>
            <option value="D">OPTION D</option>
          </select>
          <br />
          <button type='submit'>Add Question</button>
          <br /><br />
          <button type='submit'>Back</button><button type='submit'>Continue</button>
        </form>
      </div>

    </div>
  );
}

export default CreateQuizQuestionEntry;