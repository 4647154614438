import React from 'react';
import './generic.css';

function Link({ linkTitle, linkDesc, linkUrl }) {
  return (
    <div>
      <a href={linkUrl}>{linkTitle}</a>{linkDesc} < br />
    </div>
  );
}

export default Link;