import React from 'react';
import styles from './Quiz.module.scss';
import Logo from '../../Generic/logo';
import { Button, Grid, Input } from '@material-ui/core';

function CreateQuiz() {
  return (
    <div className={styles.home}>
      <Grid container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={1} sm={3} md={4} xl={4}></Grid>
        <Grid container
          direction="row"
          alignItems="center"
          justifyContent="center"
          xs={10} sm={6} md={4} xl={4}
          className={styles.homecenter}
        >
          <Logo heightlength={'10vmin'} />
          <form action='/#/quizQuestionEntry'>
            <Input className={styles.textBox}
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
                fontWeight: "bold",
                margin: "1rem 0"
              }}
              type='text' name="quizname" placeholder="QUIZ NAME" fullWidth></Input>
            <Input className={styles.textBox}
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
                fontWeight: "bold",
                margin: "1rem 0"
              }}
              type='text' name="category" placeholder="CATEGORY" fullWidth></Input>
            <Button
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
                fontWeight: "bold",
                margin: "1rem 0"
              }}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              CONTINUE
            </Button>
          </form>
        </Grid>
        <Grid item xs={1} sm={3} md={4} xl={4}></Grid>
      </Grid>
    </div>
  );
}

export default CreateQuiz;