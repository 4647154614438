import { Button } from '@material-ui/core';
import React from 'react';

function HomeBtn({ btnText, btnAction, isDisabled }) {
  return (
    <Button style={{
      fontSize: "1.6rem",
      textAlign: "center",
      fontWeight: "bold",
      margin: "1rem 0",
      padding: "40px 0"
    }}
      variant="contained" color="primary" type="button" fullWidth href={btnAction} disabled={isDisabled} >
      {btnText}
    </Button>
  );
}

export default HomeBtn;