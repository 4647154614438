import React from 'react';
import styles from './JoinGame.module.scss';
import PinEntry from './PinEntry';
import Logo from '../../Generic/logo';
import { Grid } from '@material-ui/core';

function JoinGame() {
  return (
    <div className={styles.home}>
      <Grid container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={1} sm={3} md={4} xl={4}></Grid>
        <Grid container
          direction="row"
          alignItems="center"
          justifyContent="center"
          xs={10} sm={6} md={4} xl={4}
          className={styles.homecenter}
        >
          <Logo heightlength={'10vmin'} />
          <PinEntry />
        </Grid>
        <Grid item xs={1} sm={3} md={4} xl={4}></Grid>
      </Grid>

    </div>
  );
}

export default JoinGame;