import React from 'react';
import '../Generic/generic.css';

function OptionDiv({buttonValue}) {
  return (
    <div className='optionDiv'>
      {buttonValue}
    </div>
  );
}

export default OptionDiv;