import React from 'react';
import './App.css';
import PlayerInstructions from './Components/Player/PlayerInstructions';
import { HashRouter, Switch, Route } from 'react-router-dom';
import GetReady from './Components/Player/GetReady';
import Pages from './Components/Player/Pages';
import Lobby from './Components/Host/Lobby';
import CreateQuizQuestionEntry from './Components/Host/CreateQuizQuestionEntry';
import ConfirmQuizQuestions from './Components/Host/ConfirmQuizQuestions';
import QuestionBlock from './Components/Host/QuestionBlock';
import Home from './Components/Home/Home';
import JoinGame from './Components/Player/JoinGame/JoinGame';
import CreateQuiz from './Components/Host/Quiz/CreateQuiz';

function App() {
  return (

    <HashRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/joinGame' component={JoinGame} />
        <Route exact path='/playerInstructions' component={PlayerInstructions} />
        <Route exact path='/getReady' component={GetReady} />
        <Route exact path='/lobby' component={Lobby} />        
        <Route exact path='/createQuiz' component={CreateQuiz} />
        <Route exact path='/quizQuestionEntry' component={CreateQuizQuestionEntry} />
        <Route exact path='/quizQuestionConfirmation' component={ConfirmQuizQuestions} />
        <Route exact path='/questionBlock' component={QuestionBlock} />
        <Route exact path='/pages' component={Pages} />

      </Switch>
    </HashRouter>

  );
}

export default App;
